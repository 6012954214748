@import url("https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,700;1,700&display=swap");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all 200ms ease-in-out;
  font-family: "Space Mono", monospace;
  font-weight: bold;
}
:root {
  --primary: hsla(128, 91%, 82%, 1);
  --secondary: hsla(248, 13%, 87%, 1);
  --bold-dark: hsla(250, 15%, 8%, 1);
  --normal-dark: hsla(248, 10%, 15%, 1);
  --gray: hsla(249, 5%, 53%, 1);
}

body {
  background-color: var(--bold-dark);
  color: var(--primary);
}
p {
  color: var(--gray);
}
